.footer {
  border-top: 1px solid #e5e7eb;

  .container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 3rem 2rem;
      gap: 2rem;
    }

    @media (min-width: 1024px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .logoSection {
    margin-bottom: 2px;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    .logo {
      display: flex;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;

      .logoIcon {
        height: 32px;
        width: 32px;
      }

      h2 {
        margin-left: 12px;
        font-size: 1.25rem;
        font-weight: 600;
      }
    }

    p {
      margin-top: 8px;
      font-size: 0.875rem;
      color: #000000;
      max-width: 20rem;
    }

    .socialContainer {
      margin-top: 2rem;

      .socialLinks {
        margin-top: 0.5rem;
        display: flex;
        gap: 1rem;

        a {
          color: #6b7280;
          &:hover {
            color: #725cff;
          }
        }
      }
    }
  }

  .linksGrid {
    margin-top: 0.75rem;

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;

    @media (min-width: 640px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 1.5rem;
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 2rem;
    }

    .linkColumn {
      h3 {
        font-size: 0.875rem;
        font-weight: 600;
      }

      ul {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        a {
          font-size: 0.875rem;
          color: #6b7280;
          &:hover {
            color: #725cff;
          }
        }
      }
    }
  }

  .bottomSection {
    max-width: 1280px;
    margin: 0 auto;
    border-top: 1px solid #cebefe;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media (min-width: 1024px) {
      padding-left: 32px;
      padding-right: 32px;
    }

    p {
      font-size: 0.875rem;
      color: #6b7280;
    }

    .legalLinks {
      margin-top: 16px;
      display: flex;
      gap: 24px;

      @media (min-width: 768px) {
        margin-top: 0;
      }

      a {
        font-size: 0.875rem;
        color: #6b7280;
        &:hover {
          color: #725cff;
        }
      }
    }
  }
  .bottomSectionDesc {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
    font-size: 0.875rem;
    color: #6b7280;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 6rem;

    @media (max-width: 768px) {
      & {
        padding-bottom: 5rem;
      }
    }
  }
}

